/* You can add global styles to this file, and also import other style files */
body{
    overflow: auto;
}

.iframe_opened {
  pointer-events: none;

  >.blurred-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    filter: blur(3px);
  }
}


#iframe_smag {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 1500px) {
  body {
    overflow: auto;
  }
}


.modalConfirmCss{
 
  min-width: 150px;
  min-height: 150px;
  z-index: 1010 !important;
  position: fixed !important;
  display: flex !important;
}

.modalConfirmCss .k-window-content{
  background-color: white;
}

.modalUserMessage{
 
  min-width: 150px;
  min-height: 150px;
  z-index: 90005 !important;
  position: fixed !important;
  display: flex !important;
}

.modalUserMessage .k-window-content{
  background-color: white;
}
